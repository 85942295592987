<script>
import { mask } from 'vue-the-mask';
import VIcon from '@/components/Icon.vue';

export default {
  components: {
    VIcon,
  },
  directives: {
    mask,
  },
  inject: {
    $validator: '$validator',
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    label: String,
    mask: [Array, String],
    model: [String, Number],
    money: Boolean,
    name: String,
    disabled: Boolean,
    maxlength: Number,
    validate: [Object, String],
  },
  data() {
    return {
      focused: false,
      value: '',
      ref: null,
      showPass: false
    };
  },
  watch: {
    model: {
      immediate: true,
      handler() {
        this.value = this.model;
      },
    },
  },
  methods: {
    input(e) {
      this.value = this.money
        ? e.target.value.replace(/\./g, '').replace(/\,/g, '.').replace('R$ ', '')
        : e.target.value;
      this.$emit('input', this.value);
    },
    showPassword() {
      this.$refs[this.ref].type = this.$refs[this.ref].type === 'text' ? 'password' : 'text';
      this.showPass = (this.$refs[this.ref].type === 'text');
    },
    handlerOnFocus(e) {
      this.focused = true
      this.$emit('focus', e);
    },
    handlerOnBlur(e) {
      this.focused = false
      this.$emit('blur', e);
    }
  },
  mounted() {
    const randon = Math.random().toString(36).substring(7);
    this.ref = `ref-${randon}`;
  }
};
</script>

<template>
  <div :class="{ 'focused': focused, 'has-error': errors.has(name) }" class="textbox">
    <label class="textbox__label">{{label}}</label>
    <input
      :maxlength="maxlength"
      v-if="mask"
      v-bind="$attrs"
      :name="name"
      :value="value"
      v-mask="mask"
      @blur="handlerOnBlur"
      @focus="handlerOnFocus"
      @input="input"
      v-validate="validate"
      :disabled="disabled"
      class="textbox__input"/>
    <money
      v-else-if="money"
      v-bind="$attrs"
      :name="name"
      :value="value"
      @input.native="input"
      @focus.native="handlerOnFocus"
      @blur.native="handlerOnBlur"
      v-validate="validate"
      class="textbox__input"/>
    <input
      :maxlength="maxlength"
      v-else
      v-bind="$attrs"
      :name="name"
      :value="value"
      @input="input"
      @focus="handlerOnFocus"
      @blur="handlerOnBlur"
      v-validate="validate"
      :disabled="disabled"
      class="textbox__input"
      :ref="ref"/>
    <v-icon
      v-if="$attrs.type === 'password'"
      @click="showPassword"
      class="textbox__view-password"
      :name="showPass ? 'view' : 'hide'"/>
    <span v-text="errors.first(name)" class="textbox__error"/>
  </div>
</template>

<style lang="postcss">
input[type="email"] {
    text-transform: lowercase;
}
.textbox {
  position: relative;
  text-align: left;
  width: 100%;
  &.focused &__input {
    border-bottom-color: var(--color-green);
  }
  &.has-error &__input {
    border-bottom-color: var(--color-red);
  }
  &:not(:last-of-type) {
    margin-bottom: 2em;
  }
  & ::placeholder {
    color: var(--color-grey-medium);
    opacity: 1;
  }
  & :-ms-input-placeholder {
    color: var(--color-grey-medium);
  }
  & ::-ms-input-placeholder {
    color: var(--color-grey-medium);
  }
  &__label {
    display: block;
  }
  &__input {
    border-bottom: 1px solid #cdcdcd;
    padding: .75rem 2rem .75rem 0;
    transition: border-bottom-color .25s;
    width: 100%;
    background-color: transparent;
  }
  &__view-password {
    position: absolute 2.375rem 0 auto auto !important;
    size: 1.5rem !important;
  }
  &__error {
    display: block;
    color: var(--color-red);
    font-size: .8125rem;
    margin-top: .4375rem;
  }
}
</style>
