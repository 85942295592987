var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "textbox",
      class: { focused: _vm.focused, "has-error": _vm.errors.has(_vm.name) },
    },
    [
      _c("label", { staticClass: "textbox__label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm.mask
        ? _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask,
                    expression: "mask",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.validate,
                    expression: "validate",
                  },
                ],
                staticClass: "textbox__input",
                attrs: {
                  maxlength: _vm.maxlength,
                  name: _vm.name,
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.value },
                on: {
                  blur: _vm.handlerOnBlur,
                  focus: _vm.handlerOnFocus,
                  input: _vm.input,
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          )
        : _vm.money
        ? _c(
            "money",
            _vm._b(
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.validate,
                    expression: "validate",
                  },
                ],
                staticClass: "textbox__input",
                attrs: { name: _vm.name, value: _vm.value },
                nativeOn: {
                  input: function ($event) {
                    return _vm.input.apply(null, arguments)
                  },
                  focus: function ($event) {
                    return _vm.handlerOnFocus.apply(null, arguments)
                  },
                  blur: function ($event) {
                    return _vm.handlerOnBlur.apply(null, arguments)
                  },
                },
              },
              "money",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.validate,
                    expression: "validate",
                  },
                ],
                ref: _vm.ref,
                staticClass: "textbox__input",
                attrs: {
                  maxlength: _vm.maxlength,
                  name: _vm.name,
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.value },
                on: {
                  input: _vm.input,
                  focus: _vm.handlerOnFocus,
                  blur: _vm.handlerOnBlur,
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
      _vm.$attrs.type === "password"
        ? _c("v-icon", {
            staticClass: "textbox__view-password",
            attrs: { name: _vm.showPass ? "view" : "hide" },
            on: { click: _vm.showPassword },
          })
        : _vm._e(),
      _c("span", {
        staticClass: "textbox__error",
        domProps: { textContent: _vm._s(_vm.errors.first(_vm.name)) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }